body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;  
  font-weight: 500;
}

body * {
  font-family: inherit;
  font-weight: inherit;
}

.Editor {
  background-color: white;
  padding: 40px;
}

.pg-viewer-wrapper {
  overflow: hidden !important;
}

.pg-viewer-wrapper .pg-viewer .pdf-viewer-container .pdf-viewer .pdf-canvas  canvas {
  width: 100%;
}

.sortableItem-handle {
  position: relative;
  top: 1px;
  display: block;
  width: 10px;
  height: 10px;
  opacity: 0.25;
  margin-right: 20px;
  cursor: row-resize;
  background: -webkit-linear-gradient(
    top,
    #000,
    #000 20%,
    #fff 0,
    #fff 40%,
    #000 0,
    #000 60%,
    #fff 0,
    #fff 80%,
    #000 0,
    #000
  );
  background: linear-gradient(
    180deg,
    #000,
    #000 20%,
    #fff 0,
    #fff 40%,
    #000 0,
    #000 60%,
    #fff 0,
    #fff 80%,
    #000 0,
    #000
  );
}

.sortableItem {
  display: flex;
  background: #fff;
  align-items: center;
  height: 40px;
  padding: 0 20px;
  width: 100%;
  color: #777;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  font-size: 10pt;
}
